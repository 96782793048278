import React from "react";
import { Col, Row } from "react-bootstrap";
import Fade from "react-reveal/Fade";

function Careers() {
  const card = {
    img: "/assets/bms/ark-1.png",
    title: "ARK: ",
    description:
      "We aim to be one of the largest \
      IoT Device Engineering and Technology experts. We are passionate about advanced and\
      sustainable IoT solutions, innovation, and quality.",
    subtitle:
      " Our engineering solutions ensures that the overall system is scalable, reliable and efficient.",
    imgRight: true,
  };

  return (
    <>
      <div className="shadeBg">
        <Fade bottom>
          <div className="text-center mt-4 mb-4">
            <h3>We are Hiring</h3>
            <h1 className="text-renonLite">Careers</h1>
          </div>

          <Row className="justify-content-center text-center mt-4 mb-4">
            <Col xs={12} md={12} lg={8} xl={8}>
              {card.description}
            </Col>
          </Row>
        </Fade>
      </div>
      <Fade bottom>
        <div
          className="text-center  mt-4 mb-4 breathingSpace"
          xs={1}
          md={2}
          lg={3}
          xl={3}
        >
          <h2>Available Positions</h2>
          <a
            href="https://www.linkedin.com/jobs/"
            className="whiteButton"
            target="_blank"
          >
            Current Openings
          </a>
        </div>
      </Fade>
      <Fade bottom>
        <div className="breathingSpace text-align-left mt-4 mb-4">
          <h5>Want to Contact Us Anyway?</h5>
          <p>
            For your application or in case of any question, please use the
            following e-mail address:{" "}
            <a href="mailto:contact@akhilamsolutions.com">mailto:contact@akhilamsolutions.com</a>
          </p>
          <p>
            Your application should contain the following three documents as PDF
            files:
            <ul>
              <li>Resume / Curriculum vitae</li>
              <li>A cover letter (max. 1 page) </li>
              <li>
                Educational certificates and job references (if available)
              </li>
            </ul>
          </p>
          <a href="mailto:contact@akhilamsolutions.com" className="whiteButton">
            Apply today
          </a>
        </div>
      </Fade>
    </>
  );
}

export default Careers;
