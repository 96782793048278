import React from "react";
import HeroSection from "../../components/HeroSection";
import Fade from "react-reveal/Fade";
import SectionHeader from "../../components/SectionHeader";

function Certifications() {
  const pageHeader = {
    title: "Product Certifications",
    subtitle: "Ensuring adherance to Industrial Standards",
    description:
      "Rigorously validating adherence to industry standards and protocols, ensuring that the product's interoperability functionalities",
    details: "",
    img: "/assets/certs/1.png",
  };

  const cardLv = {
    img: "/assets/certs/2.webp",
    title: "Regulatory Compliance Verification",
    description:
      "Ensuring adherence to industry-specific regulations and standards, validating that the IoT product meets necessary compliance requirements for market entry and operation.",
    subtitle: "Validation of Compliance to Standards",
    imgRight: true,
  };

  const cardMv = {
    img: "/assets/certs/3.webp",
    title: "Security and Privacy Auditing",
    description:
    "Conducting rigorous security and privacy audits to verify robust data protection measures, safeguarding user privacy and preventing potential cybersecurity vulnerabilities within the IoT ecosystem.",
    subtitle: "Focused review of Cybersecure systems",
    imgRight: false,
  };

  const cardHv = {
    img: "/assets/certs/4.webp",
    title: "Quality Assurance Validation",
    description:
    "Verifying product quality and performance through comprehensive testing, ensuring that the IoT device meets established benchmarks for reliability, durability, and overall operational excellence within the targeted market.",
    subtitle: "Versatile QA expertize",
    imgRight: true,
  };

  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardLv} />
        </div>
      </Fade>
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardMv} />
        </div>
      </Fade>
      <br />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardHv} />
        </div>
      </Fade>
      <Fade bottom>
        <div className="text-center mt-4 mb-4">
          <a
            href="mailto:contact@akhilamsolutions.com"
            className="whiteButton mt-4 mb-4"
          >
            Contact Sales
          </a>
        </div>
      </Fade>
    </>
  );
}

export default Certifications;
