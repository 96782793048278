import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { Formik } from "formik";
import * as Yup from "yup";
import { Col, Row } from "react-bootstrap";
import "./styles.css";
import emailjs from "emailjs-com";

function Footer() {
  const [subscribed, setSubscribed] = useState(false);
  const handleClose = () => setSubscribed(false);
  const handleShow = () => setSubscribed(true);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*Email is required"),
  });

  return (
    <footer className="footer mt-auto py-3 bg-myGray">
      <div className="container-fluid text-md-left breathingSpace">
        <Row>
          <Col xs={6} md={3} lg={2}>
            Company
            <ul className="list-unstyled">
              <li>
                <a href="/careers">Careers</a>
              </li>
            </ul>
          </Col>
          <Col xs={6} md={3} lg={2}>
            Products
            <ul className="list-unstyled">
              <li>
                <a href="/platform/devices">Devices</a>
              </li>
              <li>
                <a href="/software/cloud">Cloud</a>
              </li>
              <li>
                <a href="/software/ai">Applied AI</a>
              </li>
            </ul>
          </Col>
          <Col xs={6} md={3} lg={2}>
            Services
            <ul className="list-unstyled">
              <li>
                <a href="/software/product">Product Consulting</a>
              </li>
              <li>
                <a href="/software/design">Design Consulting</a>
              </li>
            </ul>
          </Col>
          <Col xs={6} md={3} lg={2}>
            Quality Control
            <ul className="list-unstyled">
              <li>
                <a href="/software/qa">Testing and QA</a>
              </li>
              <li>
                <a href="/software/certifications">Certifications</a>
              </li>
              <li>
                <a href="/software/interop">Interoperability</a>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={12} lg={4} className="text-center">
            <p>Subscribe to Our News Letter:</p>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                // Simulate submitting to database, shows us values submitted, resets form
                setTimeout(() => {
                  // alert(JSON.stringify(values, null, 2));
                  emailjs
                    .send(
                      "service_x41j80h",
                      "template_fnkno2m",
                      values,
                      "7d-ChrCkKIDeATAKZ"
                    )
                    .then(
                      (response) => {
                        handleShow();
                        // console.log("SUCCESS!", response);
                        resetForm();
                      },
                      (error) => {
                        // console.log("FAILED...", error);
                      }
                    );
                  setSubmitting(false);
                }, 100);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit} className="form-inline">
                  <Row>
                    <Col xs={12} md={12} lg={8}>
                      <Form.Group>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className={
                            touched.email && errors.email ? "has-error" : null
                          }
                        />
                        {touched.email && errors.email ? (
                          <div className="error-message">{errors.email}</div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={12} lg={4}>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                        className="greenButton"
                      >
                        Subscribe
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
            {subscribed && (
              <p className="text-renonLite">Subscribed Succesfully!</p>
            )}
            <br />
            <Col>
              <a
                href="https://www.linkedin.com/in/"
                aria-label="LinkedIn"
                target="_blank"
              >
                <FaLinkedin style={{ color: "0077b5" }} size={28} />
              </a>
              {"   "}
              <a
                href="https://www.facebook.com/"
                aria-label="Facebook"
                target="_blank"
              >
                <FaFacebook style={{ color: "4267B2" }} size={28} />
              </a>
              {"   "}
              <a
                href="https://twitter.com/"
                aria-label="Twitter"
                target="_blank"
              >
                <FaTwitter style={{ color: "00acee" }} size={28} />
              </a>
              {"   "}
              <a
                href="https://www.instagram.com/"
                aria-label="Instagram"
                target="_blank"
              >
                <FaInstagram style={{ color: "8a3ab9" }} size={28} />
              </a>
              {"   "}
              <a
                href="https://www.youtube.com/"
                aria-label="YouTube"
                target="_blank"
              >
                <FaYoutube style={{ color: "red" }} size={28} />
              </a>
            </Col>
            <div className="footer-copyright">
              Akhilam Solutions LLC., <br />
              Copyright © 2024 Akhilam Solutions LLC. All rights reserved.
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
}

export default Footer;
