import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles.css";

function LogoCarousel() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const listPartners = [
    "https://www.raspberrypi.com/",
    "https://beagleboard.org/bone",
    "https://www.raspberrypi.com/products/ai-kit/",
    "https://www.nabto.com/guide-to-iot-esp-32/",
    //"https://www.nvidia.com/en-in/autonomous-machines/embedded-systems/",
    //"https://www.intel.com/content/www/us/en/products/details/nuc.html",
    //"https://www.qualcomm.com/developer/robotics-rb5-development-kit#overview",
  ];

  const openInNewTab = (index) => {
    console.log("img clicked: ", index);
    return listPartners[index];
  };

  return (
    <>
      <div className="text-center mb-4 mt-4 text-renonLite">
        {/* <h1>500+ MWh in the field</h1> */}
        <h3 className="text-renonDark">
          Building Trusted Products by leveraging popular IoT SDKs and Solutions
        </h3>
      </div>
      <Carousel
        responsive={responsive}
        infinite={true}
        swipeable={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        centerMode
      >
        {Array.from({ length: 3 }).map((_, idx) => (
          <a href={listPartners[++idx]} target="_blank" key={idx}>
            <img
              key={idx}
              src={`./assets/partners/partner` + `${idx + 1}` + `.png`}
              alt={`./assets/partners/partner` + `${idx + 1}` + `.png`}
              width={100}
            />
          </a>
        ))}
      </Carousel>
    </>
  );
}

export default LogoCarousel;
