import React from "react";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import FormComponent from "../../components/FormComponent";
import SectionHeader from "../../components/SectionHeader";
import "./styles.css";

function Renon360() {
  const pageHeader = {
    title: "Applied AI",
    subtitle: "Revolutionary AI Solutions for your Business Success",
    description:
      "Elevate efficiency, insights, and innovation using cutting-edge Applied AI. Transform possibilities into reality with our intelligent solutions today.",
    details: "",
    img: "/assets/renon360/ai.png",
  };

  const cardsDetails = [
    {
      title: "Artificial Intelligence",
      img: "/assets/renon360/artificial-intelligence.png",
      description: "Intelligent system delivery based on application needs",
    },
    {
      title: "Machine Learning",
      img: "/assets/renon360/ml.png",
      description:
        "Predictive fault and error algorithms to eliminate downtimes",
    },
    {
      title: "Data Science",
      img: "/assets/renon360/data.png",
      description:
        "Data-driven and secure monitoring of the IoT systems based on real-time data",
    },
  ];

  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Fade bottom>
        <Container fluid className="mt-4 mb-4">
          <h4 className="text-center mt-4 mb-4">
            Our platform is focused to perform the following 3 major functions
          </h4>
          <br />
          <Col
            xs={9}
            md={9}
            className="align-items-center justify-content-center w-100 breathingSpace mt-4 mb-4"
          >
            {cardsDetails.map((c, index) => (
              <Row className="justify-content-center mt-4 mb-4" key={index}>
                <Col
                  xs={12}
                  md={9}
                  lg={6}
                  className="d-flex align-items-center h-100"
                >
                  <Col className="d-flex justify-content-end align-items-center">
                    <img src={c.img} alt={c.img} height={64} width={64} />
                  </Col>
                  <Row className="breathingSpace">
                    <h4 className="text-renonLite">{c.title}</h4>
                    <p>{c.description}</p>
                  </Row>
                </Col>
              </Row>
            ))}
          </Col>
        </Container>
      </Fade>
      <br />
      <Fade bottom>
        <div className="text-center breathingSpace">
          <h3 className="mt-4 mb-4">Building Tomorrow's Applied AI</h3>
          <img
            src={pageHeader.img}
            alt={pageHeader.img}
	    height={192}
            className="mt-4 mb-4"
          />
          <div className="text-center mt-4 mb-4">
            <a
              href="mailto:contact@akhilamsolutions.com"
              className="whiteButton mt-4 mb-4"
            >
              Contact for Demo
            </a>
          </div>
        </div>
      </Fade>
      {/* <Fade bottom>
        <div className="text-center mt-4 mb-4">
          <h5>
            To know if we have right Cloud Offering for your application, please
            fill Requirements below...
          </h5>
        </div>
        <FormComponent halfColFill={false} />
      </Fade> */}
    </>
  );
}

export default Renon360;
