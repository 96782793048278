import React from "react";
import { Button, Card, CardGroup, Col, Container, Row } from "react-bootstrap";
import "./styles.css";

function CardsComponent({ cardsDetails, xlSize }) {
  return (
    <Container fluid>
      <Row xs={1} md={3} xl={xlSize} className="justify-content-center">
        {cardsDetails.map((c, index) => (
          <Col className="col-sm-6 col-lg-3  mb-4 mt-4" key={index}>
            <Card key={index} className="border-0 customCard d-flex h-100 ">
              <Card.Img
                variant="top"
                src={c.img}
                height={100}
                alt={c.title}
                className="mt-4"
              />
              <Card.Body>
                <Card.Title className="text-renonLite mb-4 mt-4">
                  {c.title}
                </Card.Title>
                <Card.Text> {c.description}</Card.Text>
                {c.voltage && (
                  <>
                    <Row>
                      <Col>Voltage</Col>
                      <Col>{c.voltage}</Col>
                    </Row>
                    <Row>
                      <Col>Current</Col>
                      <Col>{c.current}</Col>
                    </Row>
                    <Row>
                      <Col>Energy</Col>
                      <Col>{c.energy}</Col>
                    </Row>
                    <Row>
                      <Col>Dimension</Col>
                      <Col>
                        {c.dimensions}
                        <span>mm</span>
                      </Col>
                    </Row>
                  </>
                )}
              </Card.Body>
              {c.buttonDesc && (
                <Button className="whiteButton">{c.buttonDesc}</Button>
              )}
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default CardsComponent;
