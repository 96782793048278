import React from "react";
import HeroSection from "../../components/HeroSection";
import Fade from "react-reveal/Fade";
import SectionHeader from "../../components/SectionHeader";

function Lab() {
  const pageHeader = {
    title: "University Lab Infrastructure",
    subtitle: "The Next-Gen IoT Cloud Management Systems",
    description:
      "To facilitate IoT product research, university lab infrastructure necessitates modern hardware resources, secure networking setups, comprehensive software support, and advanced testing tools, fostering innovative development and hands-on experimentation opportunities for students.",
    details: "",
    img: "/assets/lab/1.png",
  };

  const cardLv = {
    img: "/assets/lab/2.webp",
    title: "University Connect",
    description:
      "Redefine Research and Education with Cutting-Edge University Lab Infrastructure. Inspire Curiosity, Fuel Progress.",
    subtitle: "Transforming Learning Landscapes",
    imgRight: true,
  };

  const cardMv = {
    img: "/assets/lab/3.webp",
    title: "Remote Monitoring",
    description:
    "Elevate operational oversight and maximize efficiency through our robust IoT remote monitoring solution, ensuring real-time data access, proactive maintennance alerts, and streamlined performance analysis for enhanced productivity and cost savings.",
    subtitle: "Suitable for Remote Monitoring Applications",
    imgRight: false,
  };

  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardLv} />
        </div>
      </Fade>
      <Fade bottom>
        <div className="text-center mt-4 mb-4">
          <a
            href="mailto:contact@akhilamsolutions.com"
            className="whiteButton mt-4 mb-4"
          >
            Contact Sales
          </a>
        </div>
      </Fade>
    </>
  );
}

export default Lab;
