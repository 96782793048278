import React from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import FormComponent from "../../components/FormComponent";
import HeroSection from "../../components/HeroSection";
import Fade from "react-reveal/Fade";
import SectionHeader from "../../components/SectionHeader";

function CommercialEVs() {
  const pageHeader = {
    title: "Passenger & Commercial EVs",
    subtitle: "The Next-Gen Battery Management Systems",
    description:
      "Renon battery management system forms the core of Renon battery packs, \
    which takes care of safety, reliability, efficiency and performance of the battery packs.",
    details:
      "Our BMS ensures that the packs operates at preferred state of charge, power, health and energy.",
  };
  const card1 = {
    img: "/assets/mobility/ev-2wheeler.png",
    title: "Standard driven designs",
    description:
      "We design and develop packs to suit to various testing requirements, \
      thereby safety and quality of the packs are satisfied.",
    subtitle: "AIS-048, AIS-156, UN 35.3, IEC 60950, MIL-810G",
    imgRight: true,
  };
  const card2 = {
    img: "/assets/mobility/ip67.png",
    title: "IP67 compliant Designs",
    description:
      "Designed to withstand harsh environments after multiple impacts. \
      Rugged body and sealing solutions to avoid any ingress inside the pack. ",
    subtitle: "IP67 – Water and Dust tight enclosure",
    imgRight: false,
  };
  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={card1} sizeofImgWidth={"50%"} />
        </div>
      </Fade>
      <br />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={card2} />
        </div>
      </Fade>
      <Fade bottom>
        <div className="text-center mt-4 mb-4">
          <a
            href="mailto:contact@akhilamsolutions.com"
            className="whiteButton mt-4 mb-4"
          >
            Contact Sales
          </a>
          <h5>
            To know if we have right BMS for your application, please fill
            Requirements below...
          </h5>
        </div>
        <FormComponent halfColFill={false} />
      </Fade>
    </>
  );
}

export default CommercialEVs;
