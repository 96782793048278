import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./styles.css";

function HeroSection({ cardsDetails, sizeofImgWidth }) {
  const ContentSection = () => {
    return (
      <Card className="border-0">
        <Card.Body>
          <Card.Title className="text-renonLite">
            {cardsDetails.title}
          </Card.Title>
          <Card.Text className="justifyText">
            {cardsDetails.description}
          </Card.Text>
        </Card.Body>
        <Card.Footer className="border-0">{cardsDetails.subtitle}</Card.Footer>
      </Card>
    );
  };

  return (
    <>
      <Container fluid className="justify-content-center mb-4 mt-4">
        <Row className="justify-content-center" xs={1} md={2} lg={3} xl={3}>
          {cardsDetails.imgRight ? (
            <>
              <Col className="justify-content-center">
                <ContentSection />
              </Col>
              <Col className="justify-content-center align-items-center d-flex ">
                <img
                  src={cardsDetails.img}
                  alt={cardsDetails.img}
                  width={sizeofImgWidth ? sizeofImgWidth : "100%"}
                />
              </Col>
            </>
          ) : (
            <>
              <Col className="justify-content-center align-items-center d-flex">
                <img
                  src={cardsDetails.img}
                  alt={cardsDetails.img}
                  width={sizeofImgWidth ? sizeofImgWidth : "100%"}
                />
              </Col>
              <Col className="justify-content-center">
                <ContentSection />
              </Col>
            </>
          )}
        </Row>
      </Container>
    </>
  );
}

export default HeroSection;
