import React from "react";
import HeroSection from "../../components/HeroSection";
import Fade from "react-reveal/Fade";
import SectionHeader from "../../components/SectionHeader";

function QA() {
  const pageHeader = {
    title: "Quality Assurance",
    subtitle: "Enforcing Quality through state-of-the-art Testing techniques",
    description:
      "Conducting comprehensive cross-platform IoT product testing to ensure seamless integration across diverse operating systems and devices, guaranteeing a consistent and optimal user experience for all end-users.",
    details: "",
    img: "/assets/qa/1.png",
  };

  const cardLv = {
    img: "/assets/qa/2.webp",
    title: "Comprehensive Testing Protocols",
    description:
      "Implementing meticulous testing protocols to ensure thorough evaluation of product functionality, performance, and reliability, mitigating potential defects and optimizing user experience.",
    subtitle: "Expertize in End-to-End Systems Testing",
    imgRight: true,
  };

  const cardMv = {
    img: "/assets/qa/3.webp",
    title: "Agile Testing Methodologies",
    description:
    "Employing agile testing methodologies to adapt quickly to evolving project requirements, enabling efficient detection and resolution of issues while maintaining product development timelines and quality standards.",
    subtitle: "Process driven QA approaches",
    imgRight: false,
  };

  const cardHv = {
    img: "/assets/qa/4.webp",
    title: "Robust Quality Assurance Standards",
    description:
    "Adhering to rigorous quality assurance standards throughout the product development lifecycle, fostering a culture of excellence and accountability to deliver products that meet or exceed industry benchmarks and customer expectations.",
    subtitle: "Certifications and Protocol Adherance",
    imgRight: true,
  };

  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardLv} />
        </div>
      </Fade>
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardMv} />
        </div>
      </Fade>
      <br />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardHv} />
        </div>
      </Fade>
      <Fade bottom>
        <div className="text-center mt-4 mb-4">
          <a
            href="mailto:contact@akhilamsolutions.com"
            className="whiteButton mt-4 mb-4"
          >
            Contact Sales
          </a>
        </div>
      </Fade>
    </>
  );
}

export default QA;
