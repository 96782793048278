import React from "react";
import HeroSection from "../../components/HeroSection";
import Fade from "react-reveal/Fade";
import SectionHeader from "../../components/SectionHeader";

function BMS() {
  const pageHeader = {
    title: "Akhilam Cloud",
    subtitle: "The Next-Gen IoT Cloud Management Systems",
    description:
      "The core of Akhilam IoT takes care of safety, reliability, efficiency and performance. Our Cloud ensures that the modules operates at preferred state of quality, secure, health and reliable.",
    details: "",
    img: "/assets/bms/ark-1.png",
  };

  const cardLv = {
    img: "/assets/bms/ark-lv.webp",
    title: "Industrial IoT",
    description:
      "Unlock unparalled efficiency and real-time insights with our secure and scalable industrial IoT cloud solution, tailored to optimize operations, streamline data management, and ensure seamless connectivity worldwide.",
    subtitle: "Suitable for Industrial IoT Applications",
    imgRight: true,
  };

  const cardMv = {
    img: "/assets/bms/ark-mv.webp",
    title: "Remote Monitoring",
    description:
    "Elevate operational oversight and maximize efficiency through our robust IoT remote monitoring solution, ensuring real-time data access, proactive maintennance alerts, and streamlined performance analysis for enhanced productivity and cost savings.",
    subtitle: "Suitable for Remote Monitoring Applications",
    imgRight: false,
  };

  const cardHv = {
    img: "/assets/bms/ark-hv.webp",
    title: "Agriculture",
    description:
    "Optimize crop yields and resource utilization with our comprehensive IoT agriculture solution, integrating smart sensors, data analytics, and automation to enhance precision farming and sustainable practices for increased profitability.",
    subtitle: "Suitable for Agriculture Applications",
    imgRight: true,
  };

  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardLv} />
        </div>
      </Fade>
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardMv} />
        </div>
      </Fade>
      <br />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardHv} />
        </div>
      </Fade>
      <Fade bottom>
        <div className="text-center mt-4 mb-4">
          <a
            href="mailto:contact@akhilamsolutions.com"
            className="whiteButton mt-4 mb-4"
          >
            Contact Sales
          </a>
        </div>
      </Fade>
    </>
  );
}

export default BMS;
