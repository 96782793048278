import React, { useEffect } from "react";
import "./styles.css";

function VideoComponent() {
  const videoMetaData = {
    topLine: "IoT Solution",
    headline: "Design & Development at Scale",
    headLine2: "Lifecycle management",
    description:
      "We design, develop IoT Solutions for Agriculture, Industrial IoT, Healthcare and Transportation applications with Quality mindset...",
    buttonLabel: "Explore",
    videoURI: "./assets/akhilam-intro.mp4",
    alt: "IoT Video",
  };

  useEffect(() => {
    let video = document.getElementById("videoElement");
    video.muted = true;
    video.setAttribute("playsinline", "");
    video.pause();
    video.play();
    window.onscroll = function () {
      let value = 1 + window.scrollY / -600;
      video.style.opacity = value;
    };
  });
  const scrollBelow = () => {
    window.scrollTo({
      top: window.screen.height - 112,
      behavior: "smooth",
    });
  };

  return (
    <>
      <section>
        <video
          id="videoElement"
          src={videoMetaData.videoURI}
          muted
          loop
          autoPlay
          playsinline
          alt={videoMetaData.alt}
        ></video>
        <div className="overlay"></div>
        <div className="text">
          <h2 id="topLine">{videoMetaData.topLine}</h2>
          <h3 id="headLine">{videoMetaData.headline}</h3>
          <p id="description">{videoMetaData.description}</p>
          <a href="#" onClick={scrollBelow}>
            {videoMetaData.buttonLabel}
          </a>
        </div>
      </section>
    </>
  );
}

export default VideoComponent;
