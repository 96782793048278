import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import FormComponent from "../../components/FormComponent";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { SiGooglemaps } from "react-icons/si";
import SectionHeader from "../../components/SectionHeader";
import HeroSection from "../../components/HeroSection";

function Contact() {
  const pageHeader = {
    title: "Contact Us",
    subtitle: "You are an enthusiast about IoT  ? We do, too!",
    description:
      "Use the contact form below or shoot us an email to get in touch.",
    details: "",
  };

  const cardLv = {
    img: "/assets/contact/1.webp",
    title: "Cloud Based Asset Tracking",
    description:
      "Cloud-based asset tracking enhances visibility and management of resources through real-time data access, automated updates, and scalable solutions, improving efficiency and reducing operational costs.",
    subtitle: "Gain expertise in End-to-End IoT Solution design",
    imgRight: true,
  };

  const cardMv = {
    img: "/assets/contact/2.webp",
    title: "OnVIF Protocol Stack",
    description:
    "Implementing the OnVIF protocol stack streamlines interoperability between IP-based security devices, ensuring seamless integration, enhanced communication, and standardized data exchange across diverse surveillance systems.",
    subtitle: "Build Cutting-edge IoT Cameras",
    imgRight: false,
  };

  const cardHv = {
    img: "/assets/contact/3.webp",
    title: "Drone Flight Control Stack",
    description:
    "Developing a drone flight control stack involves creating software for navigation, stability, and sensor integration, enabling precise, autonomous flight, and enhancing overall aerial mission performance and safety.",
    subtitle: "Develop skills in AI based Smart Drones",
    imgRight: true,
  };

  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardLv} />
        </div>
      </Fade>
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardMv} />
        </div>
      </Fade>
      <br />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardHv} />
        </div>
      </Fade>
      <Fade bottom>
        <Row
          className="justify-content-center style"
          xs={1}
          md={2}
          lg={3}
          xl={3}
        >
          <Col className="justify-content-center">
{/*
            <Card className="mt-4 mb-4">
              <Card.Body>
                <Card.Title className="text-renonLite">
                  R&D Center
                  <span style={{ display: "flex", justifyContent: "end" }}>
                    <a
                      href="https://www.google.com/maps/"
                      target="_blank"
                    >
                      <SiGooglemaps style={{ color: "#4a89f3" }} size={28} />
                    </a>
                  </span>
                </Card.Title>
                <Card.Text>
                  <p>
                    Lorem ipsum,
                    <br />
                    #Lorem ipsum,,
                    <p>
                    Lorem ipsum, Lorem ipsum,,
                      <br /> Lorem ipsum,
                      <p>Bengaluru, Karnataka - 560003</p>
                    </p>
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="justify-content-center mt-4 mb-4">
              <Card.Body>
                <Card.Title className="text-renonLite">
                  Design Center
                  <span style={{ display: "flex", justifyContent: "end" }}>
                    <a
                      href="https://www.google.com/maps"
                      target="_blank"
                    >
                      <SiGooglemaps style={{ color: "#4a89f3" }} size={28} />
                    </a>
                  </span>
                </Card.Title>
                <Card.Text>
                  <p>
                  Lorem ipsum, <br />
                  Lorem ipsum, <br />
                  Lorem ipsum, <br />
                  Lorem ipsum, Hyderabad – 512212
                  </p>
                  <a href="mailto:lorem@ipsum.in">lorem@ipsum.in</a>
                  <br />
                  <p className="text-renonLite">
                    {" "}
                    +91-9xxxxxxxxx | +91-9xxxxxxxxx
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
*/}
            <Card className="justify-content-center mt-4 mb-4">
              <Card.Body>
                <Card.Title>We are Social</Card.Title>
                <Card.Text>
                  Find us here:
                  <br />
                  <br />
                  <p>
                    <a
                      href="https://www.linkedin.com/in"
                      target="_blank"
                    >
                      <FaLinkedin style={{ color: "0077b5" }} size={28} />
                    </a>
                    {"      "}
                    <a
                      href="https://www.facebook.com/"
                      target="_blank"
                    >
                      <FaFacebook style={{ color: "4267B2" }} size={28} />
                    </a>
                    {"      "}
                    <a href="https://twitter.com/" target="_blank">
                      <FaTwitter style={{ color: "00acee" }} size={28} />
                    </a>
                    {"      "}
                    <a
                      href="https://www.instagram.com/"
                      target="_blank"
                    >
                      <FaInstagram style={{ color: "8a3ab9" }} size={28} />
                    </a>
                    {"      "}
                    <a
                      href="https://www.youtube.com/"
                      target="_blank"
                    >
                      <FaYoutube style={{ color: "red" }} size={28} />
                    </a>
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <FormComponent halfColFill={true} />
          </Col>
        </Row>
      </Fade>
    </>
  );
}

export default Contact;
