import React from "react";
import CardsComponent from "../../components/CardsComponent";
import CarouselItems from "../../components/CarousalItems";
import Testimonials from "../../components/Testimonials";
import HeroSection from "../../components/HeroSection";
import LogoCarousel from "../../components/LogoCarousal";
import VideoComponent from "../../components/VideoComponent";
import MFDetails from "../../components/MFDetails";
import StatsComponent from "../../components/StatsComponent";
import CaseStudies from "../../components/CaseStudies";

import Fade from "react-reveal/Fade";

function Home() {
  return (
    <>
      <VideoComponent />
      <MFDetails />
      {/* <CardsComponent /> */}
      {/* <HeroSection {...homeObjOne} /> */}
      {/* <CarouselItems /> */}
      {/* <Stepper /> */}
      {/* <Fade bottom>
        <StatsComponent />
      </Fade>*/}
      <LogoCarousel /> 
      {/* <Testimonials /> */}
      <Fade bottom>
        <CaseStudies />
      </Fade>
    </>
  );
}

export default Home;
