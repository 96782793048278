import React from "react";
import HeroSection from "../../components/HeroSection";
import Fade from "react-reveal/Fade";
import SectionHeader from "../../components/SectionHeader";

function Interop() {
  const pageHeader = {
    title: "Interoperability Testing",
    subtitle: "The Next-Gen IoT Cloud Management Systems",
    description:
      "Assessing product adaptability to diverse hardware and software specifications, guaranteeing consistent functionality and data exchange across various devices and platforms.",
    details: "",
    img: "/assets/interop/1.png",
  };

  const cardLv = {
    img: "/assets/interop/2.webp",
    title: "Compatibility with Diverse Environments",
    description:
      "Conducting extensive testing across various environments and configurations to verify the product's ability to function harmoniously within heterogeneous settings, guaranteeing consistent performance and data interchange.",
    subtitle: "Simulate heterogeneous environments for QA",
    imgRight: true,
  };

  const cardMv = {
    img: "/assets/interop/3.webp",
    title: "Inter-System Communication Evaluation",
    description:
    "Thoroughly assessing the efficiency of communication protocols between diverse systems to ensure seamless data exchange and interoperability across interconnected platforms.",
    subtitle: "Suitable for Seamless Integrations",
    imgRight: false,
  };

  const cardHv = {
    img: "/assets/interop/4.webp",
    title: "End-to-End Integration Testing",
    description:
    "Implementing comprehensive end-to-end testing processes to evaluate the product's interoperability throughout the entire workflow, addressing potential integration challenges and ensuring smooth communication between interconnected components and systems.",
    subtitle: "Workflow driven QA Operations",
    imgRight: true,
  };

  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardLv} />
        </div>
      </Fade>
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardMv} />
        </div>
      </Fade>
      <br />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardHv} />
        </div>
      </Fade>
      <Fade bottom>
        <div className="text-center mt-4 mb-4">
          <a
            href="mailto:contact@akhilamsolutions.com"
            className="whiteButton mt-4 mb-4"
          >
            Contact Sales
          </a>
        </div>
      </Fade>
    </>
  );
}

export default Interop;
