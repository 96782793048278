import React, { useState } from "react";
import { Form, Button, Container, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import "./styles.css";
import emailjs from "emailjs-com";

// RegEx for phone number validation
const phoneRegExp = /^(\+?\d[\d -]{8,12}\d)?$/;

// Schema for yup
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "*Name must have at least 2 characters")
    .max(100, "*Name can't be longer than 100 characters")
    .required("*Name is required"),
  organization: Yup.string()
    .min(2, "*Organization Name must have at least 2 characters")
    .max(100, "*Organization Name can't be longer than 100 characters")
    .required("*Organization Name is required"),
  designation: Yup.string()
    .min(2, "*Designation must have at least 2 characters")
    .max(100, "*Designation can't be longer than 100 characters")
    .required("*Designation is required"),
  email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
  phone: Yup.string()
    .matches(phoneRegExp, "*Phone number is not valid")
    .required("*Phone number required"),
  message: Yup.string().required("*Message required"),
});

const FormComponent = ({ halfColFill }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Container
      fluid
      className={halfColFill ? "halfColFillContainer" : "customContainer"}
    >
      <Formik
        initialValues={{
          name: "",
          organization: "",
          designation: "",
          email: "",
          phone: "",
          message: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);

          // Simulate submitting to database, shows us values submitted, resets form
          setTimeout(() => {
            // alert(JSON.stringify(values, null, 2));
            emailjs
              .send(
                "service_x41j80h",
                "template_fnkno2m",
                values,
                "7d-ChrCkKIDeATAKZ"
              )
              .then(
                (response) => {
                  handleShow();
                  // console.log("SUCCESS!", response);
                  resetForm();
                },
                (error) => {
                  // console.log("FAILED...", error);
                }
              );
            setSubmitting(false);
          }, 100);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            onSubmit={handleSubmit}
            className={halfColFill ? "halfColFillcustomForm" : "customForm"}
          >
            <Form.Group controlId="formName" className="mb-4">
              <Form.Label>Name :</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Full Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                className={touched.name && errors.name ? "has-error" : null}
              />
              {touched.name && errors.name ? (
                <div className="error-message">{errors.name}</div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="formOrganization" className="mb-4">
              <Form.Label>Organization :</Form.Label>
              <Form.Control
                type="text"
                name="organization"
                placeholder="Organization Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.organization}
                className={
                  touched.organization && errors.organization
                    ? "has-error"
                    : null
                }
              />
              {touched.organization && errors.organization ? (
                <div className="error-message">{errors.organization}</div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="formDesignation" className="mb-4">
              <Form.Label>Designation :</Form.Label>
              <Form.Control
                type="text"
                name="designation"
                placeholder="Designation"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.designation}
                className={
                  touched.designation && errors.designation ? "has-error" : null
                }
              />
              {touched.designation && errors.designation ? (
                <div className="error-message">{errors.designation}</div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="formEmail" className="mb-4">
              <Form.Label>Email :</Form.Label>
              <Form.Control
                type="text"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className={touched.email && errors.email ? "has-error" : null}
              />
              {touched.email && errors.email ? (
                <div className="error-message">{errors.email}</div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="formPhone" className="mb-4">
              <Form.Label>Phone :</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                placeholder="Phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                className={touched.phone && errors.phone ? "has-error" : null}
              />
              {touched.phone && errors.phone ? (
                <div className="error-message">{errors.phone}</div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="formMessage" className="mb-4">
              <Form.Label>Message :</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                placeholder="Please enter your message here"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                style={{ height: "100px" }}
                className={
                  touched.message && errors.message ? "has-error" : null
                }
              />
              {touched.message && errors.message ? (
                <div className="error-message">{errors.message}</div>
              ) : null}
            </Form.Group>
            {/*Submit button that is disabled after button is clicked/form is in the process of submitting*/}
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting}
              className="whiteButton"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-renonLite">Acknowledgement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Thank you for reaching out! </p>
          <p>Your message is noted. </p>
          <p>Our team will get in touch with you shortly!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default FormComponent;
