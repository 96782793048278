import React from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import SectionHeader from "../../components/SectionHeader";
import HeroSection from "../../components/HeroSection";

function Storage() {
  const pageHeader = {
    title: "Akihlam Devices",
    subtitle: "The Next-Gen IoT Connectivity Solution",
    description:
      "Experience the seamless Integration of our Innovative, cutting-edge IoT devices design, providing you with a user-friendly, high-tech, and personalized solution for all your connected needs",
    details: "",
  };

  const card = {
    img: "/assets/storage/storage.webp",
    title: "Key Aspects",
    description: "",
    subtitle: "Home | Commercial | Industrial",
    imgRight: true,
  };
  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Fade bottom>
        <Row
          className="justify-content-center  mt-4 mb-4"
          xs={1}
          md={2}
          lg={3}
          xl={3}
        >
          <Col className="justify-content-center">
            <ListGroup variant="flush">
              <br />
              <h4 className="text-renonLite">Modular Configuration</h4>
              <ul>
                <li>MQTT Connectivity</li>
                <li>HTTP(S) Connectivity</li>
                <li>CoAP Connectivity</li>
                <li>Data Ingest on Custom ports</li>
                <li>Scalable and Modular</li>
                <li>Telematics Unit Standard modules</li>
                <li>Reliable and Secure</li>
                <li>QoS - 0, 1, 2</li>
                <li>Custom libraries</li>
              </ul>
            </ListGroup>
            <Card.Footer>Home | Commercial | Industrial</Card.Footer>
          </Col>
          <Col className="justify-content-center align-items-center d-flex ">
            <img src={card.img} alt={card.img} width="100%" />
          </Col>
        </Row>
      </Fade>

      <Fade bottom>
        <div className="text-center mt-4 mb-4">
          <a
            href="mailto:contact@akhilamsolutions.com"
            className="whiteButton mt-4 mb-4"
          >
            Contact Sales
          </a>
        </div>
      </Fade>
    </>
  );
}

export default Storage;
