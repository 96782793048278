import React from "react";
import { Col, Row } from "react-bootstrap";
import Fade from "react-reveal/Fade";

function SectionHeader({ pageHeader }) {
  return (
    <div className="shadeBg">
      <Fade bottom>
        <div className="text-center mt-4 mb-4">
          <br />
          <br />
          <h3>{pageHeader.subtitle}</h3>
          <h1 className="text-renonLite">{pageHeader.title}</h1>
        </div>

        <Row className="justify-content-center text-center mt-4 mb-4">
          <Col xs={12} md={12} lg={8} xl={8}>
            {pageHeader.description}
            <br />
            {pageHeader.details}
          </Col>
        </Row>
      </Fade>
    </div>
  );
}

export default SectionHeader;
