import React from "react";
import HeroSection from "../../components/HeroSection";
import Fade from "react-reveal/Fade";
import SectionHeader from "../../components/SectionHeader";

function Design() {
  const pageHeader = {
    title: "Design Consulting",
    subtitle: "Transforming Ideas into Experiences",
    description:
      "Empowering Design Solutions for a Dynamic World. Our designs seamlessly integrate with brand identity, effectively conveying the vision while delivering an enriching and compelling user experience",
    details: "",
    img: "/assets/design/1.png",
  };

  const cardLv = {
    img: "/assets/design/2.webp",
    title: "Creative Problem Solving",
    description:
      "Our team employs a fusion of artistic ingenuity and analytical thinking, facilitating the development of novel design solutions that effectively address intricate challenges across various industries.",
    subtitle: "Fusion of Creative Ingenuity",
    imgRight: true,
  };

  const cardMv = {
    img: "/assets/design/3.webp",
    title: "User-Centric Focus",
    description:
    "By prioritizing user experience and continually integrating user feedback, we ensure that our designs are intuitive, engaging, and tailored to meet the diverse needs and preferences of our clients' target audience.",
    subtitle: "Enriched UX/UI and Aesthetic Versatility",
    imgRight: false,
  };

  const cardHv = {
    img: "/assets/design/4.webp",
    title: "Sustainable Design Solutions",
    description:
    "Integrating sustainable practices and materials into our design process to create environmentally conscious solutions that reduce ecological impact and foster long-term social and environmental benefits.",
    subtitle: "Balancing Aesthetics with Functionality",
    imgRight: true,
  };

  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardLv} />
        </div>
      </Fade>
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardMv} />
        </div>
      </Fade>
      <br />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardHv} />
        </div>
      </Fade>
      <Fade bottom>
        <div className="text-center mt-4 mb-4">
          <a
            href="mailto:contact@akhilamsolutions.com"
            className="whiteButton mt-4 mb-4"
          >
            Contact Sales
          </a>
        </div>
      </Fade>
    </>
  );
}

export default Design;
