import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { Fade } from "react-reveal";
import SectionHeader from "../../components/SectionHeader";

function Blog() {
  const pageHeader = {
    title: "Blog",
    subtitle: "Keep yourself up-to-date",
    description:
      "What’s trending all year round in the IoT Technology Industry.",
    details: "Hear it from us!",
  };
  const blog1 = [
    {
      title: "Top 12 most commonly used IoT protocols and standards",
      firstLine:
        "Not every IoT communication protocol is right for every deployment or device. Consider the power and security requirements before choosing one protocol over another.",
      summary:
        "There were 11.7 billion IoT connections in 2020 versus 10 billion non-IoT connections -- e.g., smartphones and computers -- according to market research firm IoT Analytics. And researchers there estimated the number of IoT connections will swell to 30.9 billion by 2025.",
      img: "/assets/blog/pexels-alexandr-podvalny-376361.jpg",
      date: "Oct 13",
      section: [
        {
          paraHeading: "Credits: https://www.techtarget.com/iotagenda/tip/Top-12-most-commonly-used-IoT-protocols-and-standards",
          paraImg: "/assets/blog/1.jpg",
          paraText:
            'The benefit and value of IoT comes from enabling the components to communicate; this ability to communicate is what moves data from endpoint devices through the IoT pipeline to central servers.This communication happens via IoT protocols, which ensure that data sent from endpoint devices, such as sensors, is received and understood by the next and subsequent steps in the connected environment, whether the next step for that data is to another endpoint device or a gateway or an application.',
        },
      ],
    },
    {
      title: "4 ways to identify fraudulent lithium-ion batteries!",
      firstLine:
        "In an industry with counterfeit batteries, wrapped in shiny new packaging, oftentimes, \
        a lot of us fall prey to fake lithium-ion batteries. In fact, what lures us as consumers\
         are big discounts and flashy photos that blind us from the truth that’s hidden behind \
         these so-called cheap branded batteries.",
      summary:
        "Now, since the art of counterfeiting has become quite precise, let’s save you the trouble \
        and enlighten you with four ways you can identify the difference between fraudulent and genuine \
        lithium-ion batteries.",
      img: "/assets/blog/danilo-alvesd-w1p05F4gyNg-unsplash.jpg",
      date: "Dec 17",
      section: [
        {
          paraHeading: "dsadasd",
          paraImg: "/assets/blog/1.jpg",
          paraText: "to occur is called the activation energy",
        },
      ],
    },
    {
      title: "Benefits of a cloud platform in the IoT",
      firstLine:
        "An IoT cloud platform is where the capabilities of IoT and cloud computing tech stacks come together to bring forth added value for consumer and business applications alike.",
      summary:
        "Indeed, the growing adoption of cloud services has fuelled the development of the Internet of Things in many sectors of business where developing on-site IoT architecture can be very expensive – keeping your data in the cloud lets you reduce the costs and has a number of other advantages. Yet, before having a look at what makes a fine IoT cloud solution, we should consider the two basic elements that it rests on: an IoT platform and cloud computing. ",
      img: "/assets/blog/can-solid-power-start-selling-all-solid-state-batteries-in-2021.jpg",
      date: "Apr 21",
      section: [
        {
          paraHeading: "dsadasd",
          paraImg: "/assets/blog/1.jpg",
          paraText: "to occur is called the activation energy",
        },
      ],
    },
    {
      title: "Real World IoT Applications in Different Domains",
      firstLine:
        "The Government and engineers can use IoT to analyze the often-complex factors of town planning specific to each city. The use of IoT applications can aid in areas like water management, waste control, and emergencies.",
      summary:
        "Statistics estimate the ever-growing world population to reach nearly 10 billion by the year 2050. To feed such a massive population one needs to marry agriculture to technology and obtain best results. There are numerous possibilities in this field. One of them is the Smart Greenhouse.A greenhouse farming technique enhances the yield of crops by controlling environmental parameters. However, manual handling results in production loss, energy loss, and labor cost, making the process less effective.",
      img: "/assets/blog/lithium-battery-aa-cells.jpg",
      date: "May 28",
      section: [
        {
          paraHeading: "dsadasd",
          paraImg: "/assets/blog/1.jpg",
          paraText: "to occur is called the activation energy",
        },
      ],
    },
  ];

  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Fade bottom>
        <Container fluid>
          <Row className="justify-content-center">
            {blog1.map((c, index) => (
              <>
                {index % 2 !== 0 ? <></> : <Col xs={1} md={1} lg={1}></Col>}
                <Col lg={5} className="mb-4 mt-4" key={index}>
                  <Card key={index} className="border-1  d-flex h-100 ">
                    <Card.Body>
                      <img
                        src={c.img}
                        alt={c.img}
                        width="100%"
                        className="mt-4 mb-4"
                      />
                      <Card.Title className="text-renonLite">
                        {c.title}
                      </Card.Title>
                      <p>{c.date}</p>
                      <Card.Text className="justifyText">
                        <p>{c.firstLine}</p>
                        {c.summary}
                      </Card.Text>
                      <a
                        href={"/resources/detailedBlog/" + `${index}`}
                        _target="blank"
                      >
                        Read more
                      </a>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            ))}
          </Row>
        </Container>
      </Fade>
    </>
  );
}

export default Blog;
