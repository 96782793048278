import "./style.css";
import { Form, FormControl, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import React from "react";
import { Fade } from "react-reveal";

function Header() {
  const [selected, setSelected] = useState([]);
  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const searchContent = [
    { option: "Akhilam IoT platform", navLink: "/platform/devices" },
    {
      option: "Cloud",
      navLink: "/platform/cloud",
    },
    { option: "Mobile App", navLink: "/software/app" },
    { option: "AI/ML", navLink: "/solar" },
    { option: "Connectivity", navLink: "/platform/devices" },
    {
      option: "Possibilties and Inspirations",
      navLink: "/resources/applications",
    },
  ];

  let navigate = useNavigate();
  useEffect(() => {
    if (selected.length > 0) {
      // console.log(selected[0]);
      navigate(selected[0].navLink);
      setSelected("");
      setIsSearchOpened(!isSearchOpened);
    }
  }, [selected]);
  const showSearchBar = () => {
    setIsSearchOpened(!isSearchOpened);
  };

  return (
    <Navbar
      bg="myGray"
      sticky="top"
      expand="lg"
      collapseOnSelect
      className="animate-navbar"
    >
      <Navbar.Brand>
        <Link to="/">
          <img
            src="/assets/brand/logo1.png"
            className="img-padding"
            height={40}
            alt="logo"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle slot="end" />
      <Navbar.Collapse className="justify-content-end">
        <Nav className="text-center" defaultActiveKey="/home">
          <Nav.Link as={Link} to={"/"} eventKey={1}>
            Home
          </Nav.Link>
          <NavDropdown title="Products" eventKey={3}>
            <NavDropdown.Item as={Link} to={"/platform/devices"} eventKey={3.2}>
              Devices
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to={"/platform/cloud"} eventKey={3.3}>
              Cloud
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to={"/software/ai"} eventKey={3.4}>
              Applied AI
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Services" renderMenuOnMount eventKey={4}>
            <NavDropdown.Item
              as={Link}
              to={"/software/product"}
              eventKey={4.1}
            >
              Product Consulting
              {/* Renon 360&deg; */}
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to={"/software/design"}
              eventKey={4.2}
            >
              Design Consulting
            </NavDropdown.Item>
          <NavDropdown.Item 
		as={Link}
		to={"/software/qa"}
		eventKey={4.3}
	  >
		Testing and QA
	  </NavDropdown.Item>
     	<NavDropdown.Item
                as={Link}
                to={"/software/certifications"}
                eventKey={4.4}
          >
                Certifications
          </NavDropdown.Item>
	<NavDropdown.Item
                as={Link}
                to={"/software/interop"}
                eventKey={4.5}
          >
                Interop
          </NavDropdown.Item>
	<NavDropdown.Item
                as={Link}
                to={"/software/lab"}
                eventKey={4.6}
          >
                University Lab Infra
          </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Careers" renderMenuOnMount eventKey="5">
           {/* <NavDropdown.Item
              as={Link}
              to={"/resources/datasheets"}
              eventKey={5.1}
            >
              Testing and QA
            </NavDropdown.Item> */}
            <NavDropdown.Item
              as={Link}
              to={"/careers"}
              eventKey={5.3}
            >
              Jobs
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to={"/contact"} eventKey={5.4}>
              Internship Programs
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to={"/contact"} eventKey={6}>
            Contact Us
          </Nav.Link>
        </Nav>

        <FaSearch
          style={{ color: "cccccc" }}
          size={28}
          onClick={showSearchBar}
        />
        {isSearchOpened && (
          <Fade right>
            <Typeahead
              id="basic-typeahead-single"
              labelKey={(option) => `${option.option}`}
              onChange={setSelected}
              options={searchContent}
              placeholder="Search"
              selected={selected}
            />
          </Fade>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
