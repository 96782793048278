import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Form, Container, Modal, Row, Col } from "react-bootstrap";
import { Fade } from "react-reveal";
import * as Yup from "yup";
import SectionHeader from "../../components/SectionHeader";
import emailjs from "emailjs-com";

function PackSizeCalculator() {
  const [show, setShow] = useState(false);
  const [onContact, setOnContact] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const pageHeader = {
    title: "Battery Pack Size Calculator",
    subtitle: "Wondering how to calculate Pack Size and it's Volume?",
    description: "Talk to our Battery Experts for more details!",
    details: "",
  };

  // RegEx for phone number validation
  const phoneRegExp = /^(\+?\d[\d -]{8,12}\d)?$/;

  // Schema for yup
  const validationSchema = Yup.object().shape({
    typeOfApp: Yup.number()
      .typeError("Please choose the type of application")
      .required("*Please choose the type of application"),
    range: Yup.number()
      .typeError("Please enter a valid data")
      .required("*Range is required"),
    length: Yup.number()
      .typeError("Please enter a valid data")
      .required("*Length is required"),
    width: Yup.number()
      .typeError("Please enter a valid data")
      .required("*Width is required"),
    height: Yup.number()
      .typeError("Please enter a valid data")
      .required("*Height is required"),
    voltage: Yup.number()
      .typeError("Please choose the Pack Voltage")
      .required("*Please choose the Pack Voltage"),
    typeOfChem: Yup.number()
      .typeError("Please choose the type of Chemistry")
      .required("*Please choose the type of Chemistry"),
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*Email is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "*Phone number is not valid")
      .required("*Phone number required"),
  });

  const [batteryCapInKWhr, setBatteryCapInKWhr] = useState(0.0);
  const [volumeNeededForPack, setVolumeNeededForPack] = useState(0.0);
  const [batterCapForVolume, setBatterCapForVolume] = useState(0.0);
  const [rangeAchievable, setRangeAchievable] = useState(0.0);

  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Fade bottom>
        <Container fluid className="customContainer">
          <Formik
            initialValues={{
              typeOfApp: "",
              range: "",
              length: "",
              width: "",
              height: "",
              voltage: "",
              typeOfChem: "",
              email: "",
              phone: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);

              // Simulate submitting to database, shows us values submitted, resets form
              setTimeout(() => {
                let volumeInLtr =
                  (values.length * values.width * values.height) / 1000000;
                let volumetricEnergyDensity =
                  values.typeOfChem === "1" ? 220 : 175;
                let energyWhperKm;
                switch (values.typeOfApp) {
                  case "1":
                    energyWhperKm = 22;
                    break;
                  case "2":
                    energyWhperKm = 20;
                    break;
                  case "3":
                    energyWhperKm = 18;
                    break;
                }
                setBatteryCapInKWhr((energyWhperKm * values.range) / 1000);
                setVolumeNeededForPack(
                  (energyWhperKm * values.range) / volumetricEnergyDensity
                );

                setBatterCapForVolume(volumeInLtr * volumetricEnergyDensity);
                setRangeAchievable(
                  (volumeInLtr * volumetricEnergyDensity) / energyWhperKm
                );

                // alert(
                //   "batteryCapInKWhr: " +
                //     batteryCapInKWhr +
                //     "\nvolumeNeededForPack: " +
                //     volumeNeededForPack +
                //     "\nbatterCapForVolume: " +
                //     batterCapForVolume +
                //     "\nrangeAchievable: " +
                //     rangeAchievable
                // );
                // alert(JSON.stringify(values));
                emailjs
                  .send(
                    "service_x41j80h",
                    "template_bqqlklo",
                    values,
                    "7d-ChrCkKIDeATAKZ"
                  )
                  .then(
                    (response) => {
                      handleShow();
                      resetForm();
                      // console.log("SUCCESS!", response);
                    },
                    (error) => {
                      // console.log("FAILED...", error);
                    }
                  );
                handleShow();
                setSubmitting(false);
              }, 500);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit} className="customForm">
                <Form.Group controlId="formName" className="mb-4">
                  <Form.Label>Type of application :</Form.Label>
                  <Form.Select
                    size="sm"
                    name="typeOfApp"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.typeOfApp}
                    className={
                      touched.typeOfApp && errors.typeOfApp ? "has-error" : null
                    }
                  >
                    <option>Choose the Speed</option>
                    <option value="1">High Speed (more than 80Kmph)</option>
                    <option value="2">Medium Speed (50 to 80Kmph)</option>
                    <option value="3">Low Speed (less than 50Kmph)</option>
                  </Form.Select>
                  {touched.typeOfApp && errors.typeOfApp ? (
                    <div className="error-message">{errors.typeOfApp}</div>
                  ) : null}
                </Form.Group>
                <Form.Group controlId="formOrganization" className="mb-4">
                  <Form.Label>Range Needed :</Form.Label>
                  <Form.Control
                    type="text"
                    name="range"
                    placeholder="Number of Kms needed"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.range}
                    className={
                      touched.range && errors.range ? "has-error" : null
                    }
                  />
                  {touched.range && errors.range ? (
                    <div className="error-message">{errors.range}</div>
                  ) : null}
                </Form.Group>
                <Form.Label>Available Battery bucket Volume</Form.Label>
                <Form.Group controlId="formlength" className="mb-4">
                  <Form.Label>&raquo; Length :</Form.Label>
                  <Form.Control
                    type="text"
                    name="length"
                    placeholder="Length in mm"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.length}
                    className={
                      touched.length && errors.length ? "has-error" : null
                    }
                  />
                  {touched.length && errors.length ? (
                    <div className="error-message">{errors.length}</div>
                  ) : null}
                </Form.Group>
                <Form.Group controlId="formWidth" className="mb-4">
                  <Form.Label>&raquo; Width :</Form.Label>
                  <Form.Control
                    type="text"
                    name="width"
                    placeholder="Width in mm"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.width}
                    className={
                      touched.width && errors.width ? "has-error" : null
                    }
                  />
                  {touched.width && errors.width ? (
                    <div className="error-message">{errors.width}</div>
                  ) : null}
                </Form.Group>
                <Form.Group controlId="formHeight" className="mb-4">
                  <Form.Label>&raquo; Height :</Form.Label>
                  <Form.Control
                    type="text"
                    name="height"
                    placeholder="Height in mm"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.height}
                    className={
                      touched.height && errors.height ? "has-error" : null
                    }
                  />
                  {touched.height && errors.height ? (
                    <div className="error-message">{errors.height}</div>
                  ) : null}
                </Form.Group>
                <Form.Group controlId="formvoltage" className="mb-4">
                  <Form.Label>Required Voltage:</Form.Label>
                  <Form.Select
                    size="sm"
                    name="voltage"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.voltage}
                    className={
                      touched.voltage && errors.voltage ? "has-error" : null
                    }
                  >
                    <option>Choose the Pack Voltage</option>
                    <option value="36">36 V</option>
                    <option value="48">48 V</option>
                    <option value="60">60 V</option>
                    <option value="72">72 V</option>
                  </Form.Select>
                  {touched.voltage && errors.voltage ? (
                    <div className="error-message">{errors.voltage}</div>
                  ) : null}
                </Form.Group>
                <Form.Group controlId="formChemistry" className="mb-4">
                  <Form.Label>Battery Chemistry Type:</Form.Label>
                  <Form.Select
                    size="sm"
                    name="typeOfChem"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.typeOfChem}
                    className={
                      touched.typeOfChem && errors.typeOfChem
                        ? "has-error"
                        : null
                    }
                  >
                    <option>Choose the Type of Battery Chemistry</option>
                    <option value="1">NMC</option>
                    <option value="2">LFP</option>
                  </Form.Select>
                  {touched.typeOfChem && errors.typeOfChem ? (
                    <div className="error-message">{errors.typeOfChem}</div>
                  ) : null}
                </Form.Group>
                <Form.Group controlId="formEmail" className="mb-4">
                  <Form.Label>Email :</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className={
                      touched.email && errors.email ? "has-error" : null
                    }
                  />
                  {touched.email && errors.email ? (
                    <div className="error-message">{errors.email}</div>
                  ) : null}
                </Form.Group>
                <Form.Group controlId="formPhone" className="mb-4">
                  <Form.Label>Phone :</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    className={
                      touched.phone && errors.phone ? "has-error" : null
                    }
                  />
                  {touched.phone && errors.phone ? (
                    <div className="error-message">{errors.phone}</div>
                  ) : null}
                </Form.Group>
                {/*Submit button that is disabled after button is clicked/form is in the process of submitting*/}
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className="whiteButton"
                >
                  Calculate
                </Button>
              </Form>
            )}
          </Formik>
          <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Results</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container fluid className="justify-content-center">
                <Row>
                  <p className="text-renonLite">
                    To achieve the Required Range
                  </p>
                </Row>
                <div className="breathingSpace">
                  <Row>
                    <Col md={8}>Battery Capacity</Col>
                    <Col>: {batteryCapInKWhr.toFixed(2)} KWh</Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={8}>
                      Space needed to accomodate the above Pack Capacity
                    </Col>
                    <Col>: {volumeNeededForPack.toFixed(2)} litres</Col>
                  </Row>
                </div>
                <hr />
                <Row>
                  <p className="text-renonLite">
                    What can you accomodate in the available space in your
                    vehicle?
                  </p>
                </Row>
                <div className="breathingSpace">
                  <Row>
                    <Col md={8}>Battery Capacity</Col>
                    <Col>: {(batterCapForVolume / 1000).toFixed(2)} KWh</Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={8}>
                      Range that is achievable with this Pack Capacity
                    </Col>
                    <Col>: {rangeAchievable.toFixed(2)} Kms</Col>
                  </Row>
                </div>
                <br />
                <Row>
                  <span className="text-renonLite">
                    Your response noted. Our team will get in touch with you
                    shortly!
                  </span>
                  <br />
                  <span style={{ "font-size": "0.8rem" }}>
                    The calculations are based on certain assumptions and hence
                    to be used for reference purpose only.
                  </span>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        </Container>
      </Fade>
    </>
  );
}

export default PackSizeCalculator;
