import React from "react";
import HeroSection from "../../components/HeroSection";
import Fade from "react-reveal/Fade";
import { Col, Row } from "react-bootstrap";
import FormComponent from "../../components/FormComponent";

function Mobility() {
  const card1 = {
    img: "/assets/mobility/standards.png",
    title: "Standard driven designs",
    description:
      "We design and develop packs to suit to various testing requirements, \
      thereby safety and quality of the packs are satisfied.",
    subtitle: "AIS-048, AIS-156, UN 35.3, IEC 60950, MIL-810G",
    imgRight: true,
  };
  const card2 = {
    img: "/assets/mobility/ip67.png",
    title: "IP67 compliant Designs",
    description:
      "Designed to withstand harsh environments after multiple impacts. \
      Rugged body and sealing solutions to avoid any ingress inside the pack. ",
    subtitle: "IP67 – Water and Dust tight enclosure",
    imgRight: false,
  };
  const card3 = {
    img: "/assets/mobility/thermal.png",
    title: "Thermal design Expertise",
    description:
      "Our thermal designs are built with high predictability accuracy through thermal calculation, \
      simulation and testing.",
    subtitle:
      "maximum differential temperature across cells is limited to 5 degrees celsius.",
    imgRight: true,
  };
  const card4 = {
    img: "/assets/mobility/comm.png",
    title: "Smart and Connected Packs",
    description:
      "All our packs are smart and connected with BLE and Wi-Fi modules. \
      GSM connectivity can be extended with add-on modules.",
    subtitle:
      "In-filed data is being used to analyze battery health and battery safety \
      to extend the life of the packs with advanced safety protection features",
    imgRight: false,
  };

  return (
    <>
      <div className="shadeBg">
        <div className="text-center mt-4">
          <br />
          <h3>In-Charge To</h3>
          <h1 className="text-renonLite ">Recharge Nature</h1>

          <Fade left>
            <img
              src="/assets/ev-scope.webp"
              alt="/assets/ev-scope.webp"
              width="75%"
            />
            <br />
          </Fade>
        </div>
      </div>
      <div className="text-center mt-4 mb-4">
        <Row className="justify-content-center mt-4 mb-4">
          <Col xs={12} md={12} lg={8} xl={8}>
            Renon mobility packs are designed to perform under different ride
            and environmental conditions on Indian roads. Our packs and designs
            are built modular to adopt to varying needs of customer requirements
          </Col>
        </Row>
        <Row className="justify-content-center mt-4 mb-4 text-renonLite">
          <Col xs={12} md={12} lg={8} xl={8}>
            With nearly a decade of experience in battery pack design and
            development, our packs are designed with Quality first approach as
            the core philosophy
          </Col>
        </Row>
      </div>
      <br />
      <br />
      <Fade bottom>
        <Row className="text-center mt-4 mb-4">
          <h3>Our Design Philosophy</h3>
          <h2 className="text-renonLite">QUALITY FIRST APPROACH</h2>
        </Row>
      </Fade>
      <br />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={card1} sizeofImgWidth={"50%"} />
        </div>
      </Fade>
      <br />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={card2} sizeofImgWidth={"50%"} />
        </div>
      </Fade>
      <br />
      <Fade bottom>
        <HeroSection cardsDetails={card3} sizeofImgWidth={"50%"} />
      </Fade>
      <br />
      <Fade bottom>
        <HeroSection cardsDetails={card4} />
      </Fade>
      <br />
      <Fade bottom>
        <Row className="justify-content-center mt-4 mb-4">
          <Col xs={12} md={12} lg={8} xl={8}>
            <div className="text-center mt-4 mb-4">
              <h5>
                To design and develop Customize battery pack for your
                application , please fill your Requirements below...
              </h5>
            </div>
          </Col>
        </Row>
        <FormComponent />
      </Fade>
    </>
  );
}

export default Mobility;
