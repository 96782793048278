import React, { useState } from "react";
import { Card, Carousel, Col, Container, Row } from "react-bootstrap";

import "./styles.css";

function CaseStudies() {
  const listResources = [
    {
      img: "/assets/cases/smart-house.png",
      title: "Privacy Centric Smart Home Solutions",
      description:
        "Privacy-centric smart home solutions prioritize the protection of your personal data and privacy. These innovative technologies ensure that your connected devices, like cameras and voice assistants, don't compromise your security. Features such as end-to-end encryption, robust access controls, and offline processing empower users to enjoy the convenience of a smart home without sacrificing their privacy and security.",
      type: "Project Brief",
    },
    {
      img: "/assets/cases/digital-asset-management.png",
      title: "Remote Asset Monitoring",
      description:
        "Remote asset management solutions enable efficient monitoring and control of valuable assets from afar. These systems employ IoT sensors, GPS tracking, and cloud-based platforms to provide real-time insights into asset location, status, and performance. By optimizing maintenance schedules, reducing downtime, and enhancing security, remote asset management solutions boost productivity and asset longevity. They are essential in industries like logistics, manufacturing, and energy management.",
      type: "Project Brief",
    },
    // {
    //   img: "/assets/cases/c3.png",
    //   title: "Battery pack for Robotic application",
    //   description:
    //     "This application is designed with a unique architecture where the battery pack \
    //   communicates with the charger, inverter and the vehicle separately and it is designed \
    //   within a specific volume constraint. This pack is designed for higher peak discharge of\
    //   more than 2C for 30S.",
    //   type: "Case Study",
    // },
    {
      img: "/assets/cases/artificial-intelligence.png",
      title: "AI Driven Smart Home",
      description:
        "AI-driven smart homes harness artificial intelligence to enhance automation, comfort, and security. These systems employ machine learning algorithms to adapt to user preferences, optimizing energy usage and creating personalized experiences. AI-powered cameras and sensors can detect anomalies and send alerts for potential security breaches. Voice assistants like Siri, Alexa, or Google Assistant utilize AI to understand and respond to voice commands, making daily tasks more convenient.",
      type: "Project Brief",
    },
  ];
  return (
    <div className="shadeBg">
      <div className="text-center mt-4 mb-4">
        <br />
        <h1 className="text-renonLite">Resources</h1>
      </div>
      <Container fluid>
        <Row xs={1} md={3} className="justify-content-md-center ">
          {listResources.map((item, index) => (
            <Col className="col-sm-6 col-lg-3 mb-4 mt-4" key={index}>
              <Card className="border-0 customCard  d-flex h-100" key={index}>
                <Card.Title className="text-renonLite mb-4 mt-4 breathingSpace ">
                  {item.title}
                </Card.Title>
                <Card.Img
                  src={item.img}
                  alt={item.img}
                  width={128}
                  height={128}
                />

                <Card.Body>
                  <Card.Title className="mt-4 mb-4">{item.type} </Card.Title>
                  <Card.Text className="justifyText">
                    {item.description} <br />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default CaseStudies;
