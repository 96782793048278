import React from "react";
import { Col, Row, Card, Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import SectionHeader from "../../components/SectionHeader";

const pageHeader = {
  title: "Applications",
  subtitle: "Enormous possibilities and Innovations",
  description: "Curious to know more ",
  details: "Some Inspirations below!",
};
const appsList = [
  {
    appImg: "/assets/apps/ev-scooter.png",
    appTitle: "E-SCOOTER",
    appSubtitle: "Accelerate change.",
  },
  {
    appImg: "/assets/apps/ev-cycle.png",
    appTitle: "E-BIKE",
    appSubtitle: "Push the Pedals of Innovation.",
  },
  {
    appImg: "/assets/apps/ev-auto.png",
    appTitle: "E-RICKSHAW",
    appSubtitle: "Drive miles away from carbon footprints.",
  },
  {
    appImg: "/assets/apps/ev-wheelchair.png",
    appTitle: "WHEELCHAIR",
    appSubtitle: "Freedom in seamless mobility.",
  },
  {
    appImg: "/assets/apps/ev-solar.png",
    appTitle: "SOLAR STREET LIGHTS",
    appSubtitle: "Illuminating the road ahead.",
  },
  {
    appImg: "/assets/apps/ev-motor.png",
    appTitle: "SOLAR WATER PUMP",
    appSubtitle:
      "Simply versatile. \
    Suitable for residential and commercial purposes.",
  },
  {
    appImg: "/assets/apps/ev-house.png",
    appTitle: "SOLAR HOME LIGHTING",
    appSubtitle: "Housing the power of sunlight.",
  },
  {
    appImg: "/assets/apps/ev-powerconditionunit.png",
    appTitle: "POWER CONDITIONING UNIT",
    appSubtitle:
      "Monitors and charges the Battery through a Solar or Grid/DG set.",
  },
  {
    appImg: "/assets/apps/ev-storage7.5MWh.png",
    appTitle: "ENERGY STORAGE SYSTEM (Mid Range)",
    appSubtitle: "For a balanced and resilient energy infrastructure.",
  },
  {
    appImg: "/assets/apps/ev-ess.png",
    appTitle: "ENERGY STORAGE SYSTEM",
    appSubtitle: "For a balanced and resilient energy infrastructure.",
  },
  // {
  //   appImg: "/assets/apps/ev-solarchargecontroller.png",
  //   appTitle: "SOLAR CHARGER CONTROLLER",
  //   appSubtitle: "Engineered to Regulate.",
  // },
];

function Applications() {
  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Fade bottom>
        <Container fluid className="text-center pt-4 mt-4 mb-4">
          <Row
            xs={1}
            md={2}
            lg={2}
            xl={2}
            className="justify-content-md-center"
          >
            {appsList.map((c, index) => {
              return (
                <Col className="text-renonLite " key={index}>
                  <img src={c.appImg} alt={c.appImg} height={128} />
                  <Card.Title className="text-renonDark ">
                    {c.appTitle}
                  </Card.Title>
                  <p>{c.appSubtitle}</p>
                  <hr />
                </Col>
              );
            })}
          </Row>
          <div className="text-center mt-4 mb-4">
            <a
              href="mailto:contact@akhilamsolutions.com"
              className="whiteButton mt-4 mb-4"
            >
              Contact Us
            </a>
          </div>
        </Container>
      </Fade>
    </>
  );
}

export default Applications;
