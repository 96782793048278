import React from "react";
import Fade from "react-reveal/Fade";
import HeroSection from "../../components/HeroSection";
import SectionHeader from "../../components/SectionHeader";

function Solar() {
  const pageHeader = {
    title: "Akhilam AI/ML",
    subtitle: "Reliable AI/ML Solutions",
    description:
      "Akhilam AI/ML application lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
    details: "",
  };
  const card1 = {
    img: "/assets/solar/solar.jpg",
    title: "Key Aspects",
    description:
      "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
    subtitle:
      "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem",
    imgRight: true,
  };

  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={card1} />
        </div>
      </Fade>
      <br />
      <Fade bottom>
        <div className="text-center mt-4 mb-4">
          <a
            href="mailto:lorem@lorem.com"
            className="whiteButton mt-4 mb-4"
          >
            Contact Sales
          </a>
        </div>
      </Fade>
    </>
  );
}

export default Solar;
