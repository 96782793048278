import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Fade } from "react-reveal";
import SectionHeader from "../../components/SectionHeader";
function About() {
  const pageHeader = {
    title: "About Us",
    subtitle: "Meet us to know more",
    description: "We are IoT Design & Development Company with",
    details: " commitment towards Sustainable Future",
  };
  const content = {
    title: "Secure. Scalable. Reliable.",
    title2: "Our Group of Companies",
  };
  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Row className="breathingSpace mt-4 mb-4">
        <Col className="justify-content-start">
          <img
            src="/assets/ev-scope-bg-color.webp"
            alt="Company"
            className="img-fluid rounded-circle"
          />
        </Col>

        <Col xs={12} sm={6} md={6} className="justifyText">
          <p>
            Akhilam India is an lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum.
          </p>
          <p>
            Akhilam India is an lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum. 
            Akhilam India is an lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
            lorem ipsum lorem ipsum lorem ipsum lorem ipsum.
          </p>
        </Col>
      </Row>
      <Fade bottom>
        <div className="text-center mt-4 mb-4">
          <a href="/team" className="whiteButton">
            Our Team
          </a>
          <br /> <br />
          <h3 className="text-renonLite">{content.title}</h3>
          <h5>For a Responsible Future</h5>
        </div>
        <Row className="justify-content-center text-center mt-4 mb-4">
          <Col xs={12} md={12} lg={12} xl={12}>
            <iframe
              src="https://www.youtube.com/embed/"
              frameborder="0"
              allow="autoplay; encrypted-media; fullscreen;"
              title="video"
              width={"50%"}
              height="360px"
            />
          </Col>
        </Row>
      </Fade>
    </>
  );
}

export default About;
