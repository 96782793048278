import React from "react";
import CardsComponent from "../../components/CardsComponent";
import Fade from "react-reveal/Fade";
import { Button } from "react-bootstrap";

function BatteryPacks() {
  const cardsDetails = [
    {
      img: "/assets/alpha.png",
      title: "eV Battery - Alpha",
      description:
        "Alpha packs are most suited for vehicles where range and torque is a key factor to decide",
      buttonDesc: "Contact Sales",
      voltage: "48V",
      current: "30 / 35Ah",
      energy: "1.44/1.68 kWh",
      dimensions: "205*160*250",
    },
    {
      img: "/assets/beta.png",
      title: "eV Battery - Beta",
      description:
        "Beta packs are most suited for vehicles where range and speed is a key factor to decide",
      buttonDesc: "Contact Sales",
      voltage: "60V",
      current: "30Ah",
      energy: "1.80 kWh",
      dimensions: "165*145*335",
    },
    {
      img: "/assets/gamma.png",
      title: "eV Battery - Gamma",
      description:
        "Gamma packs are most suited for vehicles where range and speed is a key factor to decide",
      buttonDesc: "Contact Sales",
      voltage: "48V",
      current: "35Ah",
      energy: "1.68 kWh",
      dimensions: "178*158*255",
    },
  ];

  return (
    <>
      <div className="shadeBg">
        <Fade bottom>
          <div className="text-center mb-4 mt-4">
            <br />
            <h1 className="text-renonLite">READY TO GO</h1>
            <h3>Li-Ion Battery Packs for Electric Vehicles</h3>
            <br />
          </div>
        </Fade>
      </div>
      <Fade bottom>
        <CardsComponent cardsDetails={cardsDetails} />
        <div className="text-center mt-4 mb-4">
          <p>
            Not present, What you are looking for? Don't worry! We've got you!
          </p>
          <Button className="whiteButton">Customize with Us</Button>
        </div>
      </Fade>
    </>
  );
}

export default BatteryPacks;
