import React from "react";
import HeroSection from "../../components/HeroSection";
import Fade from "react-reveal/Fade";
import SectionHeader from "../../components/SectionHeader";

function Product() {
  const pageHeader = {
    title: "Product Consulting",
    subtitle: "Seamless Integration of IoT Solutions",
    description:
      "Ensuring our clients' IoT products are resilient against potential cyber threats within the dynamic technological landscape.",
    details: "",
    img: "/assets/product/1.png",
  };

  const cardLv = {
    img: "/assets/product/2.webp",
    title: "Interdisciplinary Expertise",
    description:
      "Our interdisciplinary expertise ensures holistic understanding of hardware, software, and connectivity, enabling us to offer comprehensive guidance for seamless integration of innovative IoT solutions.",
    subtitle: "Expertise in Hardware and Software",
    imgRight: true,
  };

  const cardMv = {
    img: "/assets/product/3.webp",
    title: "Strategic Innovation",
    description:
    "With a strategic innovation approach, we continuously anticipate market shifts, enabling us to develop cutting-edge IoT products that resonate with consumer demands and stay ahead of the competition.",
    subtitle: "To create Cutting-edge IoT Products",
    imgRight: false,
  };

  const cardHv = {
    img: "/assets/product/4.webp",
    title: "Security-Focused Approach",
    description:
    "By prioritizing a security-focused approach, we implement stringent measures to protect data integrity, ensuring our clients' IoT products are resilient against potential cyber threats within the dynamic technological landscape.",
    subtitle: "Safeguard against Cyber-security attacks",
    imgRight: true,
  };

  return (
    <>
      <SectionHeader pageHeader={pageHeader} />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardLv} />
        </div>
      </Fade>
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardMv} />
        </div>
      </Fade>
      <br />
      <Fade bottom>
        <div className="mb-4 mt-4">
          <HeroSection cardsDetails={cardHv} />
        </div>
      </Fade>
      <Fade bottom>
        <div className="text-center mt-4 mb-4">
          <a
            href="mailto:contact@akhilamsolutions.com"
            className="whiteButton mt-4 mb-4"
          >
            Contact Sales
          </a>
        </div>
      </Fade>
    </>
  );
}

export default Product;
