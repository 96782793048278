
import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { MdDataExploration } from "react-icons/md";
import { GiProcessor, GiFactoryArm } from "react-icons/gi";

import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

function MFDetails() {
  const cardsDetails = [
    {
      img: GiProcessor,
      heading: "DEVICES",
      imgSrc: "./assets/icons/cpu.png",
      title:
        "Design and Development of Smart Home Devices",
      link: "/platform/devices",
    },
    {
      img: GiFactoryArm,
      imgSrc: "./assets/icons/secure-data.png",
      heading: "CLOUD",
      title:
        "State-of-the-art IoT platform with intelligent device monitoring & management",
      link: "/platform/cloud",
    },
    {
      img: MdDataExploration,
      heading: "Applied AI",
      imgSrc: "./assets/icons/artificial-intelligence.png",
      title:
        "AI Solutions for Smart Home to Cloud applications",
      link: "/software/ai",
    },
  ];

  return (
    <>
      <div className="text-renonDark">
        <Fade bottom>
          <Card className="text-center mt-4 mb-4 border-0">
            <Card.Body>
              <h3>We offer expertise in Design and Development of End-to-end IoT Solutions</h3>
              <h2 className="text-renonLite">
                SCALABLE, RELIABLE & EFFICIENT
              </h2>
            </Card.Body>
          </Card>
        </Fade>
        <Container fluid>
          <Row xs={1} md={3} lg={4} className="justify-content-md-center">
            <Fade bottom>
              {cardsDetails.map((c, index) => {
                return (
                  <Col className="h-100 mb-4 mt-4" key={index}>
                    <Card
                      key={index}
                      border="light"
                      className="text-center border-0 d-flex h-100"
                    >
                      <Card.Body>
                        <img src={c.imgSrc} alt={c.imgSrc} height={64} />
                        <br></br>
                        <h2 className="text-renonLite">{c.heading}</h2>
                        <p>{c.title}</p>
                        {/* <Link to={c.link} className="whiteButton">
                        Know more
                      </Link> */}
                        <a href={c.link} className="whiteButton">
                          Know more
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Fade>
          </Row>

          <Row className="breathingSpace">
            <img src="./assets/ev-scope-bg-color.webp" alt="ev-scope" />
            <Fade bottom>
              <div className="text-center pt-4 mt-4 mb-4">
                <h2 className="text-renonDark">Wired to transform</h2>
                <h1 className="text-renonLite">The IoT Solution space.</h1>
              </div>
            </Fade>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default MFDetails;
